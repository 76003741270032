import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import data from "../../content/pages/about-us.yaml"
import "./about-us.css"
import limparImageMapper from "../components/limpar-svg-images"
import heroBg from "../images/limpar-section-background-3-q80.webp"

const AboutUsPage = () => {
    const [borderAnimation, setBorderAnimation] = React.useState(false)
    const certImageSize = 600;

    React.useEffect(
        () => {
          const checkItemPosition = () => {
            const element = document.querySelector('#lp-about-us-hero')

            setBorderAnimation(Math.abs(element.getBoundingClientRect().top) > element.getBoundingClientRect().height - window.innerHeight / 2)
          }

          window.addEventListener('scroll', checkItemPosition )
          return () => window.removeEventListener('scroll', checkItemPosition)
        }, []
    )

    return (
        <Layout headerColorMode="dark" pageTitle={ data.meta.title } enableBorderAnim>
            <section id="lp-about-us-hero"
                style={{
                    backgroundImage: `url("${heroBg}")`
                }}
                className={`lp-about-us-hero container-fluid lp-dark-hero lp-border-transition lp-btm-right-radius-transition ${ borderAnimation ? 'active' : '' }`}>
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-12">
                            <h2 className="lp-dotted-title h1">{ data.content.hero.title1[0] }&nbsp;<br className="d-none d-lg-inline"/>{ data.content.hero.title1[1] }</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            { data.content.hero.text1.map( (text, i) => {
                                return <p key={i}>{ text }</p>
                            })}
                        </div>
                        <div className="col-12 col-lg-8">
                            <img src={ limparImageMapper[data.content.hero.image] }
                                alt=""
                                style={{ marginBottom: `var(--space-3)` }}
                                className="img-fluid"
                            />
                        </div>
                    </div>
                    <div className="row flex-column align-content-center mt-5">
                        <div className="col12 col-lg-6">
                            <h2 className="lp-dotted-title">{ data.content.hero.title2 }</h2>
                            <p>{ data.content.hero.text2 }</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="lp-about-us-main container">
                <div className="row">
                    <div className="col-12 col-lg-3 offset-lg-1">
                        <h2 className="lp-dotted-title">{ data.content.main.title }</h2>
                    </div>
                    <div className="col-12 col-lg-8">
                        { data.content.main.text.map( (text, i) => {
                            return <p key={i}>{ text }</p>
                        })}
                    </div>
                </div>
                <div className="row my-5">
                    <div className="col-12 col-lg-4 d-flex flex-row justify-content-end lp-certifications">
                        <img src={ limparImageMapper[data.content.main.certifications.vertical] } className="img-fluid d-none d-lg-block" style={{ maxHeight: certImageSize}}/>
                        <img src={ limparImageMapper[data.content.main.certifications.horizontal] } className="img-fluid d-lg-none" style={{ maxHeight: certImageSize}}/>
                    </div>
                    <div className="col-12 d-lg-none">
                        <img src={ limparImageMapper[data.content.main.image] } className="img-fluid"/>
                    </div>
                    <div className="d-none d-lg-block col-lg-8">
                        <div
                        style={{
                            backgroundImage: `url("${limparImageMapper[data.content.main.image]}")`,
                            backgroundSize: `cover`,
                            backgroundPosition: `center center`,
                            minHeight: certImageSize
                         }}/>
                    </div>
                </div>
            </section>
            {/*
            <Link to="/">Go back to the homepage</Link>
                    */}
        </Layout>
)
        }

export const Head = () => <Seo title={data.meta.title} />

export default AboutUsPage

